<template>
  <div class="lcr-paiement-cdg mt-2">
    <v-btn
      class="button-icon-style"
      dark
      small
      color="#704ad1"
      title="Générer les tranches de paiement"
      v-show="showEchButton"
      @click.prevent.stop="openLcrModal"
    >
      <font-awesome-icon icon="plus" class="icon-plus" />
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="80%"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Générer les tranches de paiement</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="closeModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-tabs align-with-title background-color="#f1edfb" color="#704ad1">
            <v-tab active>
              <span class="tab-text-bold">
                Générer les tranches de paiement
              </span>
            </v-tab>
            <v-tab-item class="tab-tranche-paiement">
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Partenaire"
                    v-model="getDataLcr.partenaire"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-select
                    :items="[
                      { value: 'Jour', text: 'Jour' },
                      { value: 'Semaine', text: 'Semaine' },
                      { value: 'Mois', text: 'Mois' }
                    ]"
                    label="Type de période *"
                    outlined
                    :persistent-placeholder="true"
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="getDataLcr.type_de_periode"
                    @change="changeChequeNumber"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Société"
                    v-model="getDataLcr.societe"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Nombre d'échéance *"
                    dense
                    outlined
                    type="number"
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    v-model="getDataLcr.echeance"
                    @input="changeChequeNumber"
                    oninput="validity.valid||(value < 1 || value.length==0? value = 1 : value =value);"
                    min="1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Devise"
                    v-model="getDataLcr.devise"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Fréquence d'échéance *"
                    dense
                    outlined
                    type="number"
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    v-model="getDataLcr.frequence"
                    @input="changeChequeNumber"
                    min="0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Montant"
                    v-model="getDataLcr.montant_ttc"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6">
                  <div class="label-for-no-vuetify ">
                    <div class="custom-label">Date de début des échéances</div>
                    <date-picker
                      value-type="format"
                      format="DD-MM-YYYY"
                      type="date"
                      v-model="getDataLcr.date_echeance"
                      class="inputDatePaiement "
                      @input="changeChequeNumber"
                      :clearable="false"
                    ></date-picker>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="3" sm="3" md="3">
                  <v-text-field
                    label="A l'ordre de"
                    v-model="getDataLcr.order_de"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                  ></v-text-field
                ></v-col>
                <v-col cols xs="3" sm="3" md="3">
                  <v-text-field
                    label="Valeur en"
                    v-model="getDataLcr.valeur_en"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                  ></v-text-field
                ></v-col>
                <v-col cols xs="5" sm="5" md="5">
                  <v-select
                    color="#704ad1"
                    item-color="#704ad1"
                    :items="getRibsPartenaireLcr"
                    label="RIB du tiré Compte bancaire du tiré"
                    v-model="getDataLcr.rib"
                    outlined
                    :persistent-placeholder="true"
                    dense
                    return-object
                    item-text="bank_num_compte"
                    :loading="geTribsPartenaireLcrLoader"
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                  </v-select>
                </v-col>
                <v-col cols xs="1" sm="1" md="1">
                  <v-btn
                    class="button-icon-style"
                    dark
                    small
                    color="#704ad1"
                    title="Actualiser les données de la banque"
                    @click.prevent.stop="handleRefreshDataBank"
                  >
                    <font-awesome-icon icon="retweet" class="icon-plus" />
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6"> </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Banque du tiré"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    :value="
                      getDataLcr.rib && getDataLcr.rib.bank
                        ? getDataLcr.rib.bank
                        : ''
                    "
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6"> </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Code banque"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    :value="
                      getDataLcr.rib && getDataLcr.rib.code_bank
                        ? getDataLcr.rib.code_bank
                        : ''
                    "
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6"> </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Code guichet"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    :value="
                      getDataLcr.rib && getDataLcr.rib.code_guichet
                        ? getDataLcr.rib.code_guichet
                        : ''
                    "
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6"> </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="N de compte"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    :value="
                      getDataLcr.rib && getDataLcr.rib.num_compte
                        ? getDataLcr.rib.num_compte
                        : ''
                    "
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols xs="6" sm="6" md="6"> </v-col>
                <v-col cols xs="6" sm="6" md="6">
                  <v-text-field
                    label="Clé RIB"
                    dense
                    outlined
                    hide-details
                    :persistent-placeholder="true"
                    color="#704ad1"
                    :value="
                      getDataLcr.rib && getDataLcr.rib.cle_rib
                        ? getDataLcr.rib.cle_rib
                        : ''
                    "
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab>
              <span class="tab-text-bold">
                Visualiser les tranches de paiement
              </span>
            </v-tab>
            <v-tab-item>
              <div class="table-reglement-fac-dialog mt-2">
                <v-data-table
                  v-model="selectedTable"
                  :headers="fieldsEcheance"
                  :items="getDataLcr.lcr"
                  class="table-facture"
                  hide-default-footer
                  :calculate-widths="false"
                  :fixed-header="true"
                  hide-action
                  disable-sort
                  no-data-text="Il n'y a aucun enregistrement à afficher"
                >
                  <!-- BODY TABLE -->
                  <template v-slot:[`item.libelle`]="{ item }">
                    <td class="custom-cell">
                      {{ item.libelle ? item.libelle : '-' }}
                    </td>
                  </template>
                  <template v-slot:[`item.num`]="{}">
                    <td class="custom-cell">
                      {{ '-' }}
                    </td>
                  </template>
                  <template v-slot:[`item.date_creation`]="{ item }">
                    <td class="custom-cell">
                      {{ item.date_creation ? item.date_creation : '-' }}
                    </td>
                  </template>
                  <template v-slot:[`item.date_echance`]="{ item }">
                    <date-picker
                      v-model="item.date"
                      value-type="format"
                      format="DD-MM-YYYY"
                      type="date"
                      required
                    ></date-picker>
                  </template>
                  <template v-slot:[`item.client`]>
                    <td class="custom-cell">
                      {{ getDataLcr.partenaire ? getDataLcr.partenaire : '-' }}
                    </td>
                  </template>
                  <template v-slot:[`item.devise`]>
                    <td class="custom-cell">
                      {{ getDataLcr.devise ? getDataLcr.devise : '-' }}
                    </td>
                  </template>
                  <template v-slot:[`item.rib`]>
                    <td class="custom-cell">
                      {{
                        getDataLcr.rib && getDataLcr.rib.bank
                          ? getDataLcr.rib.bank
                          : '-'
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.amount`]="{ item, index }">
                    <td class="custom-cell">
                      <v-text-field
                        min="0"
                        :max="getDataLcr.montant_ttc"
                        type="number"
                        autocomplete="off"
                        v-model="item.value"
                        @input="changeMontantEcheance(index)"
                        class="always-show-arrows"
                      ></v-text-field>
                    </td>
                  </template>
                  <template v-slot:[`item.date_envoi`]="{}">
                    {{ '-' }}
                  </template>
                  <template v-slot:[`item.date_signature`]="{}">
                    {{ '-' }}
                  </template>
                  <template v-slot:[`item.date_valeur`]="{}">
                    {{ '-' }}
                  </template>
                  <template v-slot:[`body.append`]="{}">
                    <tr v-if="getDataLcr.lcr && getDataLcr.lcr.length > 0">
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">
                        {{ computedTotalEch }} {{ getDataLcr.devise }}
                      </td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                      <td class="custom-cell">-</td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-if="error.rib">{{ error.rib }}</div>
            <div v-if="error.date">{{ error.date }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="paiementLCR"
            :class="{ loader: false }"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    reglement: { required: true },
    factures: { required: true },
    montantFix: {
      default: false
    }
  },
  data() {
    return {
      isDatePickerDebutVisible: false,
      dialog: false,
      selectedTable: [],
      getDataLcr: {
        montant_ttc: null,
        partenaire: null,
        client_id: null,
        societe: null,
        devise: null,
        date_echeance: null,
        order_de: 'Nous-même',
        valeur_en: null,
        type_de_periode: 'Mois',
        echeance: 1,
        frequence: 1,
        rib: null,
        beneficiaire_id: null,
        lcr: []
      },
      error: {
        rib: null,
        date_echeance: null
      },
      fieldsEcheance: [
        {
          value: 'libelle',
          text: 'Libellé'
        },
        {
          value: 'num',
          text: 'Numéro'
        },
        {
          value: 'date_creation',
          text: 'Date de création'
        },
        { value: 'date_echance', text: "Date d'échéance" },
        {
          value: 'client',
          text: 'Client/Fournisseur'
        },
        {
          value: 'devise',
          text: 'Devise'
        },
        {
          value: 'rib',
          text: 'Banque du tiré'
        },
        {
          value: 'amount',
          text: 'Montant'
        },
        {
          value: 'date_envoi',
          text: "Date d'envoi"
        },
        {
          value: 'date_signature',
          text: 'Date de signature'
        },
        {
          value: 'date_valeur',
          text: 'Date valeur'
        }
      ]
    };
  },
  methods: {
    ...mapActions(['fetchRibsPartenaireLcr']),
    closeModal() {
      this.dialog = false;
      this.error = {
        rib: null,
        date_echeance: null
      };
    },
    async handleRefreshDataBank() {
      const response = await this.fetchRibsPartenaireLcr(
        this.factures[0].client_id
      );
      if (response) {
        if (this.getDataLcr.rib) {
          this.getDataLcr.rib = this.getRibsPartenaireLcr.filter(
            i => i.id == this.getDataLcr.rib.id
          )[0];
        }
      }
    },
    openLcrModal() {
      if (
        this.montantFix == true &&
        this.factures.filter(i => i.check == true).length > 0
      ) {
        let vendeur = this.factures.filter(i => i.check == true)[0];

        this.getDataLcr.societe = vendeur.vendeur;
        this.getDataLcr.beneficiaire_id = vendeur.vendeur_id;
      } else {
        this.getDataLcr.societe = this.factures[0].vendeur;
        this.getDataLcr.beneficiaire_id = this.factures[0].vendeur_id;
      }
      this.fetchRibsPartenaireLcr(this.factures[0].client_id);
      if (this.getDataLcr.montant_ttc != this.computedTotalFacture) {
        this.getDataLcr.montant_ttc = this.computedTotalFacture;
        this.changeChequeNumber(true);
      }
      //   this.getDataLcr.date_echeance = moment().format('DD-MM-YYYY');
      this.getDataLcr.devise = this.reglement.unite;
      //   if (this.reglement.type_partenaire == 'Client') {
      //     this.getDataLcr.client_id = this.reglement.filiale_id;
      //     this.getDataLcr.partenaire = this.partenaire;
      //   } else {
      this.getDataLcr.client_id = this.factures[0].client_id;
      this.getDataLcr.partenaire = this.factures[0].client;
      //   }
      this.error = null;
      this.dialog = true;
    },
    paiementLCR() {
      this.error = { rib: null, date_echeance: null };
      if (this.getDataLcr.rib == null) {
        this.error.rib = 'RIB obligatoire';
      } else if (this.getDataLcr.date_echeance == null) {
        this.error.date = "date d'échéance obligatoire";
      } else {
        this.$emit('saveDataForLcr', this.getDataLcr);
        this.dialog = false;
      }
    },
    handleChangeEcheance() {
      if (this.getDataLcr.echeance < 1) {
        this.getDataLcr.echeance = 1;
      }
      this.changeChequeNumber();
    },
    changeChequeNumber(verif) {
      // console.log(
      //   'datae format',
      //   this.formatDate(this.getDataLcr.date_echeance)
      // );
      // this.formatDate(
      //   this.getDataLcr.date_echeance
      // );
      if (verif === true && this.getDataLcr.lcr.length > 0) {
        this.getDataLcr.lcr.forEach((element, index) => {
          this.getDataLcr.lcr[index].value =
            this.getDataLcr.montant_ttc / this.getDataLcr.lcr.length;
          if (index == this.getDataLcr.echeance - 1 && index > 0) {
            this.getDataLcr.lcr[index].value = (
              this.getDataLcr.montant_ttc -
              (this.getDataLcr.montant_ttc / this.getDataLcr.echeance).toFixed(
                2
              ) *
                (this.getDataLcr.echeance - 1)
            ).toFixed(2);
          }
        });
      } else {
        this.getDataLcr.lcr = [];
        for (let i = 0; i < this.getDataLcr.echeance; i++) {
          let ech = {
            value: (
              this.getDataLcr.montant_ttc / this.getDataLcr.echeance
            ).toFixed(2),
            date: null,
            date_creation: moment().format('DD-MM-YYYY'),
            libelle: 'Tranche ' + (i + 1)
          };
          if (i == this.getDataLcr.echeance - 1 && i > 0) {
            ech.value = (
              this.getDataLcr.montant_ttc -
              (this.getDataLcr.montant_ttc / this.getDataLcr.echeance).toFixed(
                2
              ) *
                (this.getDataLcr.echeance - 1)
            ).toFixed(2);
          }

          if (
            this.getDataLcr.date_echeance != null &&
            this.getDataLcr.frequence != null
          ) {
            if (i == 0) {
              ech.date = this.getDataLcr.date_echeance;
            } else {
              if (this.getDataLcr.type_de_periode) {
                let periodeType =
                  this.getDataLcr.type_de_periode == 'Jour'
                    ? 'days'
                    : this.getDataLcr.type_de_periode == 'Semaine'
                    ? 'weeks'
                    : 'months';
                if (
                  moment(this.getDataLcr.lcr[i - 1].date, 'DD-MM-YYYY').format(
                    'M'
                  ) == 2 &&
                  moment(this.getDataLcr.lcr[i - 1].date, 'DD-MM-YYYY').format(
                    'D'
                  ) == 28
                ) {
                  let date =
                    '01-03-' +
                    moment(
                      this.getDataLcr.lcr[i - 1].date,
                      'DD-MM-YYYY'
                    ).format('Y');
                  ech.date = moment(date, 'DD-MM-YYYY')
                    .add(parseFloat(this.getDataLcr.frequence), periodeType)
                    .format('DD-MM-YYYY');
                } else {
                  ech.date = moment(
                    this.getDataLcr.lcr[i - 1].date,
                    'DD-MM-YYYY'
                  )
                    .add(parseFloat(this.getDataLcr.frequence), periodeType)
                    .format('DD-MM-YYYY');
                }
              }
            }
            this.getDataLcr.lcr.push(ech);
          }
        }
      }
    },
    changeMontantEcheance(index) {
      let sum = 0;
      let index_update =
        index == this.getDataLcr.lcr.length - 1
          ? 0
          : this.getDataLcr.lcr.length - 1;

      let index_start = index == this.getDataLcr.lcr.length - 1 ? 1 : 0;
      let index_end =
        index == this.getDataLcr.lcr.length - 1
          ? this.getDataLcr.lcr.length
          : this.getDataLcr.lcr.length - 1;
      this.getDataLcr.lcr.forEach((element, index) => {
        if (index >= index_start && index < index_end) {
          sum = parseFloat(sum) + parseFloat(element.value);
        }
      });
      this.getDataLcr.lcr[index_update].value = (
        this.getDataLcr.montant_ttc - sum
      ).toFixed(2);
    }
  },
  computed: {
    ...mapGetters([
      'clientsReglement',
      'TypeReglement',
      'getRibsPartenaireLcr',
      'geTribsPartenaireLcrLoader'
    ]),
    showEchButton() {
      return (
        (this.computedPaiementNameSelected == 'LCR' ||
          this.computedPaiementNameSelected == 'LCR Lettre de change Relevé' ||
          this.computedPaiementNameSelected.includes('LCR')) &&
        this.reglement &&
        this.reglement.filiale_id &&
        ((this.montantFix == true && this.reglement.montant_a_payer != null) ||
          (this.montantFix == false &&
            this.factures.filter(i => i.check == true).length > 0))
      );
    },
    computedMontantRest() {
      let montant = 0;
      for (let i = 0; i < this.getDataLcr.lcr.length; i++) {
        montant += parseFloat(this.getDataLcr.lcr[i].value);
      }
      if (parseFloat(montant) < parseFloat(this.getDataLcr.montant_ttc))
        return this.getDataLcr.montant_ttc - montant;
      return 0;
    },
    computedCheckNumLCR() {
      return this.getDataLcr.lcr.filter(item => item.numero);
    },
    computedPaiementNameSelected() {
      if (this.reglement && this.reglement.type_reglement != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglement.type_reglement
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglement.type_reglement + '';
    },
    computedTotalEch() {
      let sum = 0;
      this.getDataLcr.lcr.forEach(element => {
        sum = parseFloat(sum) + parseFloat(element.value);
      });
      sum = sum.toFixed(2);
      return sum;
    },
    computedTotalFacture() {
      let sum = 0;
      if (this.montantFix == false) {
        this.factures.forEach(element => {
          sum = parseFloat(sum) + parseFloat(element.montant_a_regle);
        });
      } else {
        sum = this.reglement.montant_a_payer;
      }

      sum = parseFloat(sum).toFixed(2);
      return sum;
    }
    // partenaire() {
    //   if (this.reglement && this.reglement.filiale_nom) {
    //     return this.reglement.filiale_nom;
    //   } else {
    //     let fund = this.clientsReglement.filter(
    //       i => i.id == this.reglement.filiale_id
    //     );
    //     if (fund.length) {
    //       return fund[0].name;
    //     } else {
    //       return '';
    //     }
    //   }
    // }
  },
  watch: {
    resetModalLcr: function() {
      this.error = { rib: null, date: null };
      this.getDataLcr = {
        montant_ttc: null,
        partenaire: null,
        client_id: null,
        societe: null,
        devise: null,
        date_echeance: null,
        order_de: 'Nous-même',
        valeur_en: null,
        type_de_periode: 'Mois',
        echeance: 1,
        frequence: 1,
        rib: null,
        beneficiaire_id: null,
        lcr: []
      };
    }
  },
  components: {}
};
</script>

<style lang="scss">
// .inputDatePaiementWidth {
//   width: 100% !important;
//   .mx-input-wrapper {
//     width: 100%;
//   }
// }
.lcr-paiement-cdg {
  .icon-plus {
    height: 11px;
    width: 11px;
    margin-bottom: 0px;
  }
}
.tab-tranche-paiement {
  padding: 6px 6px 6px 6px !important;
}
.date-genere-tranche {
  .v-input__prepend-inner {
    margin-top: 14px !important;
  }
}
.always-show-arrows {
  .v-input__slot {
    background-color: #e0e0e057 !important;
    border-radius: 0px;
    box-shadow: unset !important;
    margin-bottom: 0px !important;
  }
  .v-input__icon.v-input__icon--prepend-inner {
    margin-top: 2px !important;
  }
  .v-text-field__slot {
    input {
      text-align: center !important;
    }
  }
}
</style>
